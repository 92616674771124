import styled from 'styled-components';
import { Dropdown, Tooltip, Space } from 'antd';
import { AntInput } from 'uiKits/Input';
import AntTable from 'uiKits/Table';
import AntButton from 'uiKits/Button';

export const MainLayout = styled.div`
  padding: 48px 55px;
  margin-top: 10px;
`;

export const ToolBarWrapper = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

export const LeftContainer = styled.div`
  display: inherit;
  gap: 5px;
`;

export const HeadingText = styled.h4`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  margin: 0;
  margin-right: 10px;
  cursor: default;
`;

export const GoogleIconContainer = styled.span`
  width: 32px;
  height: 32px;
  border: 2px solid #000000;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-bottom: 1px;
  &:hover {
    border: 2px solid #07af7c;
    svg {
      color: #07af7c;
    }
  }
`;

export const StyledGoogleImage = styled.img`
  height: 22px;
`;

export const StyledSpace = styled(Space)`
  cursor: pointer;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledInput = styled(AntInput)`
  width: 254px;
  height: 40px;
`;

////// Table Styles

export const StyledTable = styled(AntTable)``;

export const ActionImage = styled.img`
  width: 15px;
  cursor: pointer;
`;

export const StyledDropdown = styled(Dropdown)``;

export const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
`;

export const SelectAllButton = styled(AntButton)`
  background-color: #fff;
  padding: 8px 29px;
  font-size: 12px;
  font-weight: 700;
  font-family: Muli;
  border: 2px solid #02b183;
  color: #02b183;
  border-radius: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SelectedRowsText = styled.h6`
  font-family: Muli;
  font-weight: 600;
  height: auto;
  width: auto;
  margin: 0;
  font-size: 1rem;
`;

export const DropdownWrapper = styled.div`
  cursor: pointer;
`;
