import React, { useEffect, useState } from 'react';
import { MainLayout, StyledModal } from './styles';
import Toolbar from './components/Toolbar';
import ProductListTable from './components/ProductListTable';
import {
  useGetProductsQuery,
  useDeleteProductMutation,
  useDuplicateProductsMutation,
  useGenerateProductsCsvMutation,
  useAssignCategoriesToProductsMutation,
} from 'api/productApi';
import Loader from 'components/Common/Loader';
import { setProducts } from 'slices/productsSlice';
import { message } from 'antd';
import { useGetCategoriesQuery } from 'api/categoriesApi';
import AssignModal from 'components/Common/AsignModal';

const ProductListing = () => {
  const [searchFilter, setSearchFilter] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [isAssignModal, setIsAssignModal] = useState(false);
  const [showProdListing, setIsShowProdListing] = useState(true);
  const [assignModalStates, setAssignModalStates] = useState({
    searchFilter: '',
    currentPage: 1,
    pageSize: 50,
    selectedRows: [],
  });

  // Queries And Mutations

  // Get Products Query
  const {
    data: productsData,
    isLoading: productsLoading,
    refetch: refetchProducts,
  } = useGetProductsQuery({
    searchFilter,
    sortBy,
    sortOrder,
    currentPage,
    pageSize,
    categoryFilter,
  });

  // Get Categories Query

  const { data: categoriesData, isLoading: categoriesLoading } = useGetCategoriesQuery({
    searchFilter: assignModalStates.searchFilter,
    currentPage: assignModalStates.currentPage,
    pageSize: assignModalStates.pageSize,
  });

  // Delete Product Mutation
  const [deleteProduct, { isLoading: deleteProductLoading }] = useDeleteProductMutation();

  // Duplicate Products Mutation
  const [duplicateProducts, { isLoading: duplicateProductLoading }] =
    useDuplicateProductsMutation();

  // Products CSV mutation
  const [generateCsvProduct, { isLoading: generateCsvProductLoading }] =
    useGenerateProductsCsvMutation();

  // Delete Products
  const handleDeleteProducts = async () => {
    if (selectedRows && selectedRows.length === 0) {
      message.error('Please select some products to delete');
      return;
    }

    try {
      await deleteProduct({ selectedProducts: selectedRows, isSelectAll: isSelectAll });
      resetSelectedRows();
      if (currentPage > 1) setCurrentPage(currentPage - 1);
    } catch (error) {
      message.error(error.data?.message || 'Failed to delete products');
    }
  };

  // Assign Multiple Categories to multiple Selected Products Mutation

  const [assignCategoriesToProducts, { isLoading: assigningLoading }] =
    useAssignCategoriesToProductsMutation();

  // Handle sorting change
  const handleSortChange = (column, order) => {
    setSortBy(column);
    setSortOrder(order);
  };

  // Handle search change
  const handleSearchChange = (e) => {
    setSearchFilter(e.target.value);
  };

  // Handle Duplicate Products
  const handleDuplicateProducts = (productIds) => {
    if (productIds && productIds.length === 0) {
      message.error('Please select some products to duplicate');
      return;
    }
    if (isSelectAll && productsData?.total > 50) {
      setIsModalOpen(true);
      setIsDuplicate(true);
      return;
    }
    duplicateProductsApi(productIds);
  };

  const duplicateProductsApi = async (productIds) => {
    setIsModalOpen(false);
    setIsDuplicate(false);

    try {
      await duplicateProducts({
        selectedProducts: productIds,
        isSelectAll: isSelectAll,
        totalProducts: productsData?.total,
      });
      resetSelectedRows();
    } catch (error) {
      message.error(error.data?.message || 'Failed to duplicate products');
    }
  };

  // Handle Assign
  const handleAssign = async () => {
    if (!selectedRows.length) {
      message.error('Products are not selected');
      return;
    }
    if (!assignModalStates.selectedRows.length) {
      message.error('Categories are not selected');
      return;
    }

    const res = await assignCategoriesToProducts({
      categoryIds: assignModalStates.selectedRows,
      productIds: selectedRows,
    });

    if (res.error) {
      message.error('Oops, some thing went wrong');
    }
    setIsAssignModal(false);
    resetModalSelectedRows();
    resetSelectedRows();
    message.success('Categories assigned successfully');
  };

  const resetSelectedRows = () => {
    setSelectedRows([]);
    setSelectAll(false);
    setIsModalOpen(false);
    setIsDuplicate(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length === 0) {
      resetSelectedRows();
      return;
    }
    if (newSelectedRowKeys.length < pageSize) {
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRows,
    preserveSelectedRowKeys: false,
    onChange: onSelectChange,
  };

  // Assign modal row selection
  const resetModalSelectedRows = () => {
    setAssignModalStates({
      ...assignModalStates,
      selectedRows: [],
    });
  };

  const onModalSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length === 0) {
      resetModalSelectedRows();
      return;
    }

    setAssignModalStates({
      ...assignModalStates,
      selectedRows: newSelectedRowKeys,
    });
  };

  const assignModalrowSelection = {
    selectedRows: assignModalStates.selectedRows,
    preserveSelectedRowKeys: false,
    onChange: onModalSelectChange,
  };

  const handleDownloadCsv = async () => {
    if (selectedRows.length === 0) {
      message.error('Please select some products to export');
      return;
    }
    if (isSelectAll && productsData?.total > 500) {
      setIsModalOpen(true);
      setIsDuplicate(false);
      return;
    }
    generateProductsCsvApi();
  };

  const generateProductsCsvApi = async () => {
    setIsModalOpen(false);
    setIsDuplicate(false);

    try {
      const response = await generateCsvProduct({
        selectedProducts: selectedRows,
        isSelectAll: isSelectAll,
        totalProducts: productsData?.total,
      });
      if (response.data) {
        const csvContent = response.data.data;
        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'products.csv');

        // Append the link to the document body
        document.body.appendChild(link);

        // Click the link to trigger the download
        link.click();

        // Remove the link from the document body
        document.body.removeChild(link);
        resetSelectedRows();
      }
    } catch (error) {
      console.log('error', error);
      message.error(error);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    const ids = Object.values(productsData.products).map((product) => product.id);
    setSelectedRows(ids);
  };

  const handleUnselectAll = () => {
    setSelectAll(false);
    setSelectedRows([]);
  };

  useEffect(() => {
    refetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchFilter, categoryFilter]);

  useEffect(() => {
    // save products in Global state
    setProducts(productsData?.products);
    return () => {
      setSelectedRows([]);
    };
  }, [productsData]);

  return (
    <MainLayout className="container">
      <StyledModal
        title="Confirmation"
        open={isModalOpen}
        cancelText="No"
        okText="Yes"
        onOk={() => (isDuplicate ? duplicateProductsApi(selectedRows) : generateProductsCsvApi())}
        onCancel={() => resetSelectedRows()}
      >
        <p>
          You've selected a large number of products, so we're processing current action this in the
          background. We'll notify you via email once it's complete. Are you certain about this
          selection?
        </p>
      </StyledModal>
      <Toolbar
        handleDeleteProducts={handleDeleteProducts}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        handleSearchChange={handleSearchChange}
        handleDuplicateProducts={handleDuplicateProducts}
        selectedRows={selectedRows}
        handleDownloadCsv={handleDownloadCsv}
        rowSelection={rowSelection}
        categories={categoriesData?.categories}
        setCategoryFilter={setCategoryFilter}
        categoryFilter={categoryFilter}
        setIsAssignModal={setIsAssignModal}
        setIsShowProdListing={setIsShowProdListing}
        isSelectAll={isSelectAll}
      />
      {showProdListing && (
        <ProductListTable
          products={productsData?.products}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          handleDuplicateProducts={handleDuplicateProducts}
          deleteProduct={deleteProduct}
          refetchProducts={refetchProducts}
          handleSortChange={handleSortChange}
          rowSelection={rowSelection}
          preserveSelectedRowKeys={false}
          totalProducts={productsData?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchFilter={searchFilter}
          sortBy={sortBy}
          sortOrder={sortOrder}
          pageSize={pageSize}
          setPageSize={setPageSize}
          isSelectAll={isSelectAll}
          handleSelectAll={handleSelectAll}
          resetSelectedRows={resetSelectedRows}
          handleUnselectAll={handleUnselectAll}
        />
      )}

      {!!isAssignModal && (
        <AssignModal
          isAssignModal={isAssignModal}
          setIsAssignModal={setIsAssignModal}
          selectedProducts={selectedRows}
          resetParentRows={resetSelectedRows}
          parentStates={assignModalStates}
          setParentStates={setAssignModalStates}
          total={categoriesData?.total}
          dataSource={categoriesData?.categories}
          rowSelection={assignModalrowSelection}
          resetRows={resetModalSelectedRows}
          loading={categoriesLoading || assigningLoading}
          handleAssign={handleAssign}
          localeTxt="No Data Found"
          type="product"
        />
      )}

      <Loader
        isLoading={
          productsLoading ||
          deleteProductLoading ||
          duplicateProductLoading ||
          generateCsvProductLoading
        }
      />
    </MainLayout>
  );
};

export default ProductListing;
