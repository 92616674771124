import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledPlaygroundPage = styled.div`
  position: relative;
  left: calc(50% + 31px);
  transform: translate(-50%);
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 75px;
`;

export const StyledPageContent = styled.div`
  padding: 25px 10px;
  &.locked {
    pointer-events: none;
  }
`;

export const StyledPageName = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: gray;
  font-size: 14px;
  margin-top: 8px;
  pointer-events: none;
  filter: invert(1);
`;

export const StyledPageNote = styled.div`
  color: #ec0c0c;
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 0;
  font-size: 20px;
  cursor: pointer;
  color: #02b183 !important;
`;
