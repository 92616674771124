import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import Pagination from '../Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { assignTableColumns } from './columns';

import {
  StyledModal,
  StyledTitle,
  StyledInput,
  FooterWrapper,
  StyledOkButton,
  StyledCancelButton,
  ButtonsWrapper,
  TitleInputWrapper,
  StyledTable,
  SelectAllWrapper,
  SelectAllButton,
  SelectedRowsText,
} from './styles';

const AssignModal = ({
  isAssignModal,
  setIsAssignModal,
  total,
  parentStates,
  setParentStates,
  dataSource,
  type,
  refetchData,
  loading,
  rowSelection,
  handleAssign,
  resetRows,
  localeTxt,
}) => {
  // columns
  const columns = assignTableColumns(type);

  const [pageSize, setPageSize] = useState(50);
  const [isSelectAll, setSelectAll] = useState(false);

  // search
  const handleInputChange = (e) => {
    setParentStates({
      ...parentStates,
      searchFilter: e.target.value,
    });
  };

  // Pagination
  const handlePaginationChange = (page) => {
    setParentStates({
      ...parentStates,
      currentPage: page,
    });
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
    setParentStates({
      ...parentStates,
      pageSize,
    });
  };

  const onClose = () => {
    setIsAssignModal(false);
    resetRows();
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    const ids = Object.values(dataSource).map((data) => data.id);
    setParentStates({
      ...parentStates,
      selectedRows: ids,
    });
  };

  const handleUnselectAll = () => {
    setSelectAll(false);
    setParentStates({
      ...parentStates,
      selectedRows: [],
    });
  };

  useEffect(() => {
    if (dataSource && type === 'category') {
      refetchData();
    }
  }, []);

  return (
    <StyledModal
      title={
        <>
          <TitleInputWrapper>
            <StyledTitle>Select {type === 'product' ? 'Categories' : 'Products'}</StyledTitle>
            <StyledInput
              prefix={<FontAwesomeIcon icon={faMagnifyingGlass} />}
              placeholder="Search"
              onChange={handleInputChange}
            />
          </TitleInputWrapper>
          {parentStates.selectedRows?.length > 0 && type === 'linesheetProducts' && (
            <SelectAllWrapper>
              {isSelectAll || parentStates.selectedRows?.length === total ? (
                <SelectAllButton onClick={() => handleUnselectAll()}>Unselect all</SelectAllButton>
              ) : (
                <SelectAllButton onClick={() => handleSelectAll()}>
                  Select all {total}
                </SelectAllButton>
              )}
              <SelectedRowsText>
                Selected {isSelectAll ? total : parentStates.selectedRows?.length} products
              </SelectedRowsText>
            </SelectAllWrapper>
          )}
        </>
      }
      open={isAssignModal}
      centered
      width={600}
      footer={
        <FooterWrapper>
          <ButtonsWrapper>
            <StyledCancelButton size="large" type="text" onClick={onClose}>
              Cancel
            </StyledCancelButton>
            <StyledOkButton
              disabled={parentStates.selectedRows.length ? false : true}
              size="large"
              onClick={() =>
                type === 'linesheetProducts' ? handleAssign(isSelectAll) : handleAssign()
              }
            >
              Add
            </StyledOkButton>
          </ButtonsWrapper>
        </FooterWrapper>
      }
      onCancel={onClose}
    >
      <StyledTable
        rowKey="id"
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: parentStates.selectedRows,
        }}
        columns={columns}
        dataSource={dataSource}
        size="middle"
        locale={{ emptyText: localeTxt }}
        scroll={{
          y: `calc(100vh - 400px)`,
        }}
        pagination={{
          ...Pagination({
            currentPage: parentStates?.currentPage,
            pageSize,
            total,
            onShowSizeChange,
            handlePaginationChange,
          }),
        }}
      />
      <Loader isLoading={loading} />
    </StyledModal>
  );
};

export default AssignModal;
