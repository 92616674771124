import React from 'react';
import {
  StyledModal,
  ModalTitle,
  StyledInput,
  StyledForm,
  FooterWrapper,
  ButtonsWrapper,
  StyledCancelButton,
  StyledSaveButton,
  StyledRegionDropdown,
  StyledCountryDropdown,
} from './styles';

const { TextArea } = StyledInput;

const ContactModal = ({
  isOpenModal,
  toggleModal,
  handleSave,
  type,
  editRecord = {},
  userEmail,
}) => {
  const [form] = StyledForm.useForm();
  const selectedCountry = StyledForm.useWatch('country', form);
  return (
    <StyledModal
      open={isOpenModal}
      onCancel={toggleModal}
      width={560}
      centered
      title={<ModalTitle>{type === 'Edit' ? 'Edit Contact info' : 'Contact Info'}</ModalTitle>}
      footer={
        <FooterWrapper>
          <ButtonsWrapper>
            <StyledCancelButton onClick={toggleModal} type="text">
              Cancel
            </StyledCancelButton>
            <StyledSaveButton htmlType="submit" form="contactForm">
              Save
            </StyledSaveButton>
          </ButtonsWrapper>
        </FooterWrapper>
      }
    >
      <StyledForm
        form={form}
        name="contactForm"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 12, offset: 5 }}
        layout="horizontal"
        autoComplete="off"
        onFinish={(values) => handleSave(values)}
        initialValues={{
          firstName: editRecord?.firstName,
          lastName: editRecord?.lastName,
          email: editRecord?.email,
          phone: editRecord?.phone,
          companyName: editRecord?.companyName,
          address: editRecord?.address,
          city: editRecord?.city,
          state: editRecord?.state,
          postalCode: editRecord?.postalCode,
          country: editRecord?.country,
          notes: editRecord?.notes,
        }}
      >
        <StyledForm.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'Please input first name!' }]}
        >
          <StyledInput size="large" />
        </StyledForm.Item>
        <StyledForm.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Please input last name!' }]}
        >
          <StyledInput size="large" />
        </StyledForm.Item>
        <StyledForm.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input email!' },
            { type: 'email', message: 'Please enter a valid email!' },
            () => ({
              validator(_, value) {
                if (value && value === userEmail) {
                  return Promise.reject(
                    new Error('You cannot create a contact using your own email.'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <StyledInput size="large" />
        </StyledForm.Item>
        <StyledForm.Item label="Company Name" name="companyName">
          <StyledInput size="large" />
        </StyledForm.Item>
        <StyledForm.Item label="Phone" name="phone">
          <StyledInput size="large" />
        </StyledForm.Item>
        <StyledForm.Item label="Address" name="address">
          <StyledInput size="large" />
        </StyledForm.Item>
        <StyledForm.Item label="Country" name="country">
          <StyledCountryDropdown name="country" defaultOptionLabel="Select Country" />
        </StyledForm.Item>
        <StyledForm.Item label="State/Province" name="state">
          <StyledRegionDropdown
            country={selectedCountry}
            name="province"
            defaultOptionLabel="Select Province"
          />
        </StyledForm.Item>
        <StyledForm.Item label="City" name="city">
          <StyledInput size="large" />
        </StyledForm.Item>
        <StyledForm.Item label="Postal Code" name="postalCode">
          <StyledInput size="large" />
        </StyledForm.Item>

        <StyledForm.Item label="Notes" name="notes">
          <TextArea size="large" rows={4} />
        </StyledForm.Item>
      </StyledForm>
    </StyledModal>
  );
};

export default ContactModal;
