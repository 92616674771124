import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addTextDiv,
  setActiveAction,
  setTemplateModalVisibility,
  setSelectedPage,
  addContentToPage,
  unsetSelection,
} from 'slices/editorSlice';
import Page from './Page';
import { MainContainer, StyledEditorPlayground } from './styles';
import RightClickMenu from './RightClickMenu';
import PlaygroundMenuOptions from './playgroundMenuOptions';
import PageMenuOptions from './pageMenuOptions';
import ContentMenuOptions from './contentMenuOptions';
import ImageMenuOptions from './imageMenuOptions';
import TextMenuOptions from './textMenuOptions';
import imageCursor from 'assets/images/image-regular.png';
import ImageUploadModal from './ImageUploadModal';
import { message } from 'antd';

const Playground = () => {
  const dispatch = useDispatch();
  const playgroundMenuOptions = PlaygroundMenuOptions();
  const contentMenuOptions = ContentMenuOptions();
  const imageMenuOptions = ImageMenuOptions();
  const textMenuOptions = TextMenuOptions();
  const { activeAction, changeImage } = useSelector((state) => state.editor);
  const pages = useSelector((state) => state.editor.linesheetData.pages);
  const [openImageModal, setIsOpenImageModal] = useState(false);
  const [isPageId, setIsPageId] = useState('');
  const [imgDiv, setIsImgDiv] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [menuOptions, setMenuOptions] = useState();
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const renderPages = () => {
    if (!Array.isArray(pages) || pages.length === 0) {
      return null;
    }

    const handlePageClick = (event, pageId) => {
      setIsPageId(pageId);
      const page = pages.find((p) => p.unique_page_id === pageId);
      if (page && page?.settings?.locked && activeAction !== 'pointer') {
        message.error(`Can't add ${activeAction} to a locked page`);
        dispatch(setActiveAction('pointer'));
        return;
      }

      const pageElement = event.currentTarget;
      const pageRect = pageElement.getBoundingClientRect();
      const divX = event.clientX - pageRect.left;
      const divY = event.clientY - pageRect.top;
      setIsImgDiv({ x: divX, y: divY });
      switch (activeAction) {
        case 'text':
          const newDiv = {
            x: divX,
            y: divY,
            text: 'Add Text',
            pageId,
          };
          dispatch(addTextDiv(newDiv));
          break;
        case 'image':
          setIsOpenImageModal(true);
          dispatch(setActiveAction('pointer'));
          break;
        default:
          break;
      }
    };

    return pages.map((page, idx) => (
      <Page key={idx} pageData={page} pageIdx={page.page_number} onPageClick={handlePageClick} />
    ));
  };

  const bgClickHandler = (e) => {
    e.preventDefault();
    if (e.target.closest('.ant-modal-wrap')) {
      setShowMenu(false);
      return;
    }

    const { clientY, clientX } = e;
    const isBottomHalf = clientY > window.innerHeight / 2;
    const top = clientY;
    const transform = isBottomHalf ? 'translateY(-100%)' : 'translateY(0)';
    if (e.target.closest('#playground-content-image')) {
      setMenuOptions(imageMenuOptions);
    } else if (e.target.closest('#playground-content-text')) {
      setMenuOptions(textMenuOptions);
    } else if (e.target.closest('#playground-content-product')) {
      setMenuOptions(contentMenuOptions);
    } else if (e.target.closest('.linesheet-editor-playground-page')) {
      const pageIdx = e.target.closest('.linesheet-editor-playground-page').id.split('-').pop();
      const pageId = pages[pageIdx].unique_page_id;
      dispatch(setSelectedPage({ pageId, pageIdx }));
      const page = pages[pageIdx];
      setMenuOptions(PageMenuOptions(dispatch, page));
    } else {
      setMenuOptions(playgroundMenuOptions);
    }
    setMenuPosition({ y: top, x: clientX, transform });
    setShowMenu(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    if (!event.target.closest('.linesheet-editor-playground-page')) {
      dispatch(setTemplateModalVisibility({ show: true }));
    } else {
      // Start with the event target
      let targetElement = event.target;

      // Traverse up the DOM until we find an element with the desired ID pattern
      while (targetElement && !targetElement.id.includes('page-')) {
        targetElement = targetElement.parentElement;
      }

      // Now we have the correct parent element with the ID
      const targetId = targetElement?.id ?? null;

      if (targetId) {
        const pageId = targetId.split('-').slice(-5).join('-');
        const pageIdx = pages.findIndex(({ unique_page_id }) => unique_page_id === pageId);
        const page = pages[pageIdx];
        if (page) {
          if (page.settings.locked) {
            message.error("Can't add products to a locked page");
            return;
          }
          if (page.type === 'custom') {
            message.error("Can't add products to a custom page");
            return;
          }
          // Calculate the drop position
          const rect = targetElement.getBoundingClientRect();
          const left = event.clientX - rect.left; // X position relative to the target element
          const top = event.clientY - rect.top; // Y position relative to the target element

          dispatch(setSelectedPage({ pageId, pageIdx }));

          // You can include the drop positions in the action if needed
          dispatch(addContentToPage({ left, top }));
        }
      }
    }
  };

  let cursor = 'default';
  switch (activeAction) {
    case 'text':
      cursor = 'text';
      break;
    case 'image':
      cursor = `url(${imageCursor}), copy;`;
      break;
    default:
      break;
  }

  return (
    <MainContainer>
      <StyledEditorPlayground
        id="linesheet-editor-playground"
        onContextMenu={bgClickHandler}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        cursor={cursor}
        onClick={() => dispatch(unsetSelection())}
      >
        {renderPages()}
        {showMenu && (
          <RightClickMenu
            visible={showMenu}
            x={menuPosition.x}
            y={menuPosition.y}
            transform={menuPosition.transform}
            options={menuOptions}
            onClose={() => setShowMenu(false)}
          />
        )}
      </StyledEditorPlayground>
      {(openImageModal || changeImage) && (
        <ImageUploadModal
          visible={openImageModal || changeImage}
          onClose={() => setIsOpenImageModal(false)}
          imgDiv={imgDiv}
          pageId={isPageId}
          changeImage={changeImage}
        />
      )}
    </MainContainer>
  );
};

export default Playground;
