import {
  deleteContent,
  addPageBefore,
  addPageAfter,
  duplicatePage,
  lockPage,
  unlockPage,
  deletePage,
  setActiveAction,
  realign,
  setShowConfirmTemplateModal,
} from 'slices/editorSlice';

const PageMenuOptions = (dispatch, page) => {
  const isPageLocked = page?.settings?.locked || false;

  const reAlignContents = () => {
    if (page) {
      const selectedTemplate = page.settings?.template;
      if (selectedTemplate) {
        const size = selectedTemplate.gridSize;
        const totalContentSize = size[0] * size[1];
        const currentPageContentSize = page.content?.length;
        if (currentPageContentSize > totalContentSize) {
          dispatch(setShowConfirmTemplateModal());
        } else {
          dispatch(realign());
        }
      }
    }
  };

  const options = [
    {
      label: 'Page',
      disabled: true,
    },
    {
      label: 'Add Text',
      onClick: () => dispatch(setActiveAction('text')),
      disabled: isPageLocked,
    },
    {
      label: 'Add Image',
      onClick: () => dispatch(setActiveAction('image')),
      disabled: isPageLocked,
    },
    {
      type: 'divider',
    },
    {
      label: 'Realign Content',
      onClick: () => reAlignContents(),
      disabled: isPageLocked,
    },
    {
      label: 'Delete Content',
      onClick: () => dispatch(deleteContent()),
      disabled: isPageLocked,
    },
    {
      type: 'divider',
    },
    {
      label: 'Add Page Before',
      onClick: () => dispatch(addPageBefore()),
    },
    {
      label: 'Add Page After',
      onClick: () => dispatch(addPageAfter()),
    },
    {
      label: 'Duplicate Page',
      onClick: () => dispatch(duplicatePage()),
    },
    {
      label: isPageLocked ? 'Unlock Page' : 'Lock Page',
      onClick: () => {
        const action = isPageLocked ? unlockPage : lockPage;
        dispatch(action());
      },
    },
    {
      type: 'divider',
    },
    {
      label: 'Delete Page',
      onClick: () => dispatch(deletePage()),
    },
  ];

  return options;
};

export default PageMenuOptions;
