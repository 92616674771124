import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useGoogleLogin } from '@react-oauth/google';
import SendEmailModal from '../Common/SendEmailModal';
import ContactModal from './ContactModal';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';
import Columns from './column';
import { GOOGLE_CONTACTS_SCOPE } from 'utils/constants';
import {
  useCreateContactMutation,
  useDeleteContactMutation,
  useDuplicateContactsMutation,
  useFetchGoogleContactMutation,
  useGetContactsQuery,
  useUpdateContactMutation,
} from 'api/contactsApi';
import ImageWithTooltip from '../Common/Tooltip';
import googleContactsIcon from 'assets/images/search.png';
import { faCopy, faPlus, faTrash, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  MainLayout,
  ToolBarWrapper,
  LeftContainer,
  HeadingText,
  GoogleIconContainer,
  StyledGoogleImage,
  StyledTooltip,
  StyledInput,
  StyledTable,
  SelectAllContainer,
  SelectAllButton,
  SelectedRowsText,
  StyledSpace,
} from './styles';
import { message } from 'antd';

const Contacts = () => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [editRecord, setEditRecord] = useState({});
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const isSubscribed = userData.subscription.status === 'active';
  const isOneTimeSubscription = userData.subscription.planType === 'oneTime';
  const [createContact, { isLoading: createContactLoading }] = useCreateContactMutation();

  const [duplicateContacts, { isLoading: duplicateContactsLoading }] =
    useDuplicateContactsMutation();

  const [deleteContacts, { isLoading: deleteContactsLoading }] = useDeleteContactMutation();

  const [updateContact, { isLoading: updateContactsLoading }] = useUpdateContactMutation();

  const [fetchGoogleContacts, { isLoading: fetchGoogleLoading }] = useFetchGoogleContactMutation();

  const { data: contactsList, isLoading: getContactsLoading } = useGetContactsQuery({
    searchFilter,
    sortBy,
    sortOrder,
    currentPage,
    pageSize,
    fetchAll: false,
  });

  const toggleAddModal = () => {
    setIsOpenAddModal(!isOpenAddModal);
  };

  const toggleEditModal = () => {
    setIsOpenEditModal(!isOpenEditModal);
  };

  const handleOpenEditModal = (record) => {
    setIsOpenEditModal(true);
    setEditRecord(record);
  };

  const handlePaginationChange = (page) => {
    setSelectAll(false);
    setSelectedRows([]);
    setCurrentPage(page);
  };

  const onShowSizeChange = (current, pageSize) => {
    setSelectAll(false);
    setSelectedRows([]);
    setPageSize(pageSize);
  };

  const handleSortChange = (column, order) => {
    setSortBy(column);
    setSortOrder(order);
  };

  const handleSearchDebounced = debounce((value) => {
    setSearchFilter(value);
  }, 500);

  const handleSearch = (e) => {
    handleSearchDebounced(e.target.value);
  };

  const handleAddContact = (values) => {
    createContact(values);
    setIsOpenAddModal(false);
  };

  const handleEditContact = (values) => {
    updateContact({ contactId: editRecord?.id, contactData: values });
    setIsOpenEditModal(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length < pageSize) {
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRows,
    preserveSelectedRowKeys: false,
    onChange: onSelectChange,
  };

  const handleSelectAll = () => {
    setSelectAll(true);
    setSelectedRows(contactsList?.contacts?.map((contact) => contact.id));
  };

  const handleUnselectAll = () => {
    setSelectAll(false);
    setSelectedRows([]);
  };

  const handleDuplicate = () => {
    duplicateContacts({
      selectedContacts: selectedRows,
      isSelectAll: selectAll,
      totalContacts: contactsList?.total,
    });
    setSelectAll(false);
    setSelectedRows([]);
  };

  const handleDelete = () => {
    deleteContacts({
      selectedContacts: selectedRows,
      isSelectAll: selectAll,
    });
    setSelectAll(false);
    setSelectedRows([]);
  };

  const toggleSendEmailModal = () => {
    setIsOpenSendEmailModal(!isOpenSendEmailModal);
  };

  const googleContacts = useGoogleLogin({
    onSuccess: ({ access_token }) => fetchGoogleContacts({ token: access_token }),
    scope: GOOGLE_CONTACTS_SCOPE,
  });

  return (
    <MainLayout className="container">
      <ToolBarWrapper>
        <LeftContainer>
          <StyledSpace>
            <HeadingText>Contacts</HeadingText>
            <ImageWithTooltip
              icon={faPlus}
              onClick={toggleAddModal}
              title="Add contacts"
              placement="bottom"
            />
            <ImageWithTooltip
              icon={faCopy}
              onClick={handleDuplicate}
              title="Duplicate contacts"
              placement="bottom"
            />
            <ImageWithTooltip
              icon={faTrash}
              onClick={handleDelete}
              title="Delete contacts"
              placement="bottom"
            />
            <ImageWithTooltip
              icon={faEnvelope}
              onClick={toggleSendEmailModal}
              title="Send Email"
              placement="bottom"
              disabled={isOneTimeSubscription || !isSubscribed}
            />
            <StyledTooltip title="Import contacts from Google" placement="right">
              <GoogleIconContainer onClick={googleContacts}>
                <StyledGoogleImage src={googleContactsIcon} />
              </GoogleIconContainer>
            </StyledTooltip>
          </StyledSpace>
        </LeftContainer>
        <StyledInput
          placeholder="Search"
          size="large"
          prefix={<FontAwesomeIcon icon={faSearch} />}
          onChange={handleSearch}
        />
      </ToolBarWrapper>
      <SelectAllContainer>
        {selectedRows?.length > 0 && (
          <>
            {selectAll || selectedRows?.length === contactsList?.total ? (
              <SelectAllButton onClick={handleUnselectAll}>Unselect all</SelectAllButton>
            ) : (
              <SelectAllButton onClick={handleSelectAll}>
                Select all {contactsList?.total}
              </SelectAllButton>
            )}
            <SelectedRowsText>
              Selected {selectAll ? contactsList?.total : selectedRows?.length} contacts
            </SelectedRowsText>
          </>
        )}
      </SelectAllContainer>
      <StyledTable
        rowKey="id"
        columns={Columns(
          deleteContacts,
          duplicateContacts,
          contactsList?.total,
          handleOpenEditModal,
          setSelectedRows,
        )}
        dataSource={contactsList?.contacts}
        locale={{ emptyText: 'No Data Found' }}
        rowSelection={{
          ...rowSelection,
          selectedRowKeys: selectedRows,
        }}
        onChange={(pagination, filters, sorter) => {
          // Handle column sorting change
          if (sorter && sorter.column && sorter.order) {
            handleSortChange(sorter.column.key, sorter.order === 'ascend' ? 'ASC' : 'DESC');
          }
        }}
        pagination={{
          ...Pagination({
            currentPage,
            pageSize,
            total: contactsList?.total,
            onShowSizeChange,
            handlePaginationChange,
          }),
        }}
      />
      {!!isOpenAddModal && (
        <ContactModal
          isOpenModal={isOpenAddModal}
          toggleModal={toggleAddModal}
          handleSave={handleAddContact}
          type="Add"
          userEmail={userData.email}
        />
      )}
      {!!isOpenEditModal && (
        <ContactModal
          isOpenModal={isOpenEditModal}
          toggleModal={toggleEditModal}
          handleSave={handleEditContact}
          editRecord={editRecord}
          type="Edit"
          userEmail={userData.email}
        />
      )}
      {!!isOpenSendEmailModal && (
        <SendEmailModal isOpenModal={isOpenSendEmailModal} toggleModal={toggleSendEmailModal} />
      )}
      <Loader
        isLoading={
          getContactsLoading ||
          createContactLoading ||
          duplicateContactsLoading ||
          deleteContactsLoading ||
          updateContactsLoading ||
          fetchGoogleLoading
        }
      />
    </MainLayout>
  );
};

export default Contacts;
