import styled from 'styled-components';
import { Button, Modal } from 'antd';
import AntTable from 'uiKits/Table';
import { AntInput } from 'uiKits/Input';
import AntButton from 'uiKits/Button';

export const StyledModal = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  .ant-table {
    margin-top: 0 !important;
  }
  .ant-modal-header {
    padding: 20px;
    margin: 0;
  }
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-body {
    padding: 0px 20px;
  }
`;

export const StyledTable = styled(AntTable)`
  margin-top: 0;
`;

export const FooterWrapper = styled.div`
  padding: 38px;
  background-color: #f3f3f3;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
`;

export const StyledOkButton = styled(Button)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.96px;
  color: #fff;
  border-radius: 22px;
  background-color: #02b183;
  border: none;
  width: 86px;
  height: 46px;
  &:hover {
    color: #ffffff !important;
    background-color: #02b183 !important;
    border: none !important;
  }
`;

export const StyledCancelButton = styled(Button)`
  border: none;
  color: #6c757d;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  width: 120px;
  height: 46px;
  &:hover {
    color: #ffffff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;

export const TitleInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.h5`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin: 0;
`;

export const StyledLabel = styled.label`
  font-family: Muli;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.36px;
  text-align: left;
  color: #000;
`;

export const LabelInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledInput = styled(AntInput)`
  width: 200px;
`;

export const StyledError = styled.small`
  display: block;
  text-align: center;
  color: #dc3545;
`;

export const SelectAllWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`;

export const SelectAllButton = styled(AntButton)`
  background-color: #fff;
  padding: 8px 29px;
  font-size: 12px;
  font-weight: 700;
  font-family: Muli;
  border: 2px solid #02b183;
  color: #02b183;
  border-radius: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SelectedRowsText = styled.h6`
  font-family: Muli;
  font-weight: 600;
  height: auto;
  width: auto;
  margin: 0;
  font-size: 1rem;
`;
